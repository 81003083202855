import React from "react"
import Fade from "react-reveal/Fade"
import {
  H2,
  BlueQuote,
  Paragraph,
  Span,
  TestimonialsContainer,
} from "./styled/components"
import { QuoteLeft } from "@styled-icons/fa-solid/QuoteLeft"
import healthtrackrx from "../images/healthtrackrx.png"
import reno_airport from "../images/reno_airport.jpg"
import tolleson from "../images/tolleson.png"

const Testimonials = () => {
  return (
    <TestimonialsContainer pt={5} pb={5}>
      <H2 textAlign="left" fontSize="52px">Testimonials</H2>
      <Fade bottom>
        <div className="testimonial">
          <img src={healthtrackrx} />
          <Paragraph>
            <br />
            <BlueQuote>
              <QuoteLeft size="42" />
            </BlueQuote>
            <br />
            <br />
            Wherewithal and knowledge with TDEM and other state agencies in the COVID market.
            <br />
            <br />
            <Span>Jason Bristol, CEO HealthTrackRX</Span>
            <br />
          </Paragraph>
        </div>
        <br />
        <div className="testimonial">
          <img src={reno_airport} />
          <Paragraph>
            <br />
            <BlueQuote>
              <QuoteLeft size="42" />
            </BlueQuote>
            <br />
            <br />
            WellHealth was able to quickly establish a COVID-19 testing operation to serve the ongoing demand for air travel testing.
            <br />
            <br />
            <Span>Shawna Carpenter, Reno-Tahoe International Airport</Span>
            <br />
          </Paragraph>
        </div>
        <br />
        <div className="testimonial">
          <img src={tolleson} />
          <Paragraph pb={5}>
            <br />
            <BlueQuote>
              <QuoteLeft size="42" />
            </BlueQuote>
            <br />
            <br />
            I absolutely would recommend WellHealth to be a provider in any community because of how responsive they are and the quality that is consistently delivered.
            <br />
            <br />
            <Span>George Good, Tolleson Fire Chief</Span>
            <br />
          </Paragraph>
        </div>
      </Fade>
    </TestimonialsContainer>
  )
}

export default Testimonials
